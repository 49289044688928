import state from './moduleFeatureCategoryManagementState.js'
import mutations from './moduleFeatureCategoryManagementMutations.js'
import actions from './moduleFeatureCategoryManagementActions.js'
import getters from './moduleFeatureCategoryManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

