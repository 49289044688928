import axios from '@/axios.js'

export default {
  fetchFeatureCategories ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/facility-categories')
        .then(({data: response}) => {
          commit('SET_FEATURE_CATEGORIES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFeatureCategory (context, featureCategoryId) {
    return new Promise((resolve, reject) => {
      axios.get(`/facility-categories/${featureCategoryId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeFeatureCategory (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/facility-categories', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateFeatureCategory (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/facility-categories/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyFeatureCategory ({ commit }, featureCategoryId) {
    return new Promise((resolve, reject) => {
      axios.delete(`facility-categories/${featureCategoryId}`)
        .then((response) => {
          commit('REMOVE_RECORD', featureCategoryId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
